import React, { useState } from "react"

import * as styles from "./FooterColumn.module.scss"

interface IItem {
  name: string
  url: string
  isButton?: boolean
  onClick?: () => void
}

interface IFooterColumn {
  title: string
  title2?: string
  items: [IItem]
  items2?: [IItem]
}

const FooterColumn = ({ title, items, title2, items2 }: IFooterColumn) => {
  return (
    <div className={styles.footerColumn}>
      <div>
        <h3>{title}</h3>
        <ul className={styles.hiddenUptoDesktopXl}>
          {items.map((item, i) => (
            <li key={i}>
              <a href={item.url}>{item.name}</a>
            </li>
          ))}
        </ul>

        <ul className={styles.hiddenFromDesktopXl}>
          {items.map((item, i) => (
            <li key={i}>
              {item.isButton ? (
                <button onClick={item.onClick}>{item.name}</button>
              ) : (
                <a href={item.url}>{item.name}</a>
              )}
            </li>
          ))}
        </ul>
      </div>

      {title2 && (
        <div>
          <h3 style={{ marginTop: "40px" }}>{title2}</h3>
          <ul className={styles.hiddenUptoDesktopXl}>
            {items.map((item, i) => (
              <li key={i}>
                <a href={item.url}>{item.name}</a>
              </li>
            ))}
          </ul>

          <ul className={styles.hiddenFromDesktopXl}>
            {items2?.map((item, i) => (
              <li key={i}>
                {item.isButton ? (
                  <button onClick={item.onClick}>{item.name}</button>
                ) : (
                  <a href={item.url}>{item.name}</a>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default FooterColumn
