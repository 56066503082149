import React from "react"
import FooterColumn from "../FooterColumn/FooterColumn"

import * as styles from "./Footer.module.scss"
import { useRecoilState } from "recoil"
import { AuthState, isAuthenticatedState } from "../../state/user"
import Container from "../Container/Container"
import FooterAllRights from "../FooterAllRights/FooterAllRights"
import { Link } from "gatsby"
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  WeChatIcon,
} from "@artsy/palette"

import MetadynLogo from "../../images/metadyn-logo.png"

const Footer = () => {
  const [, setAuthState] = useRecoilState(isAuthenticatedState)
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footerInner}>
          <FooterColumn
            title="About us"
            items={[
              {
                name: "About",
                url: "https://www.artsy.net/about",
                isButton: true,
                onClick: function () {
                  setAuthState(AuthState.signingIn)
                },
              },
              {
                name: "Jobs",
                url: "https://www.artsy.net/jobs",
              },
              {
                name: "Press",
                url: "https://www.artsy.net/press/press-releases",
              },
              {
                name: "Contact",
                url: "https://www.artsy.net/contact",
              },
            ]}
          />

          <FooterColumn
            title="Resources"
            items={[
              {
                name: "Open Source",
                url: "https://artsy.github.io/open-source/",
              },
              {
                name: "Blog",
                url: "https://medium.com/artsy-blog",
              },
              {
                name: "The Art Gennome Project",
                url: "https://www.artsy.net/categories",
              },
              {
                name: "Education",
                url: "https://www.artsy.net/artsy-education",
              },
            ]}
          />

          <FooterColumn
            title="Partnerships"
            items={[
              {
                name: "Artsy for Galleries",
                url: "https://partners.artsy.net/",
              },
              {
                name: "Artsy for Museums",
                url: "https://www.artsy.net/institution-partnerships",
              },
              {
                name: "Artsy for Auctions",
                url: "https://www.artsy.net/auction-partnerships",
              },
              {
                name: "Artsy for Fairs",
                url: "https://partners.artsy.net/artsy-fair-partnerships/",
              },
            ]}
          />

          <FooterColumn
            title="Support"
            items={[
              {
                name: "Visit our Help Center",
                url: "https://support.artsy.net/hc/en-us",
              },
              {
                name: "Buying on Artsy",
                url: "https://support.artsy.net/hc/en-us/categories/360003689513-Buy",
              },
            ]}
            title2="Get the App"
            items2={[
              {
                name: "iOS App",
                url: "https://apps.apple.com/us/app/artsy-buy-sell-original-art/id703796080",
              },
              {
                name: "Android App",
                url: "https://play.google.com/store/apps/details?id=net.artsy.app",
              },
            ]}
          />
        </div>

        <div className={styles.footerPoweredBy}>
          Powered by{" "}
          <a href="https://metadyn.com/">
            <img src={MetadynLogo}></img>
          </a>
        </div>

        <FooterAllRights hideOnDesktop />

        <div className={styles.footerSocial}>
          <div className={styles.footerSocialInner}>
            <Link to={`/`}>
              <svg
                height="20"
                width="20"
                name="Artsy"
                viewBox="0 0 32 32"
                fill="black100"
                className="Icon-sc-1eovgys-0 kAqktO"
              >
                <title>Artsy</title>
                <g fillRule="evenodd">
                  <path d="M0 31.963h31.876V.09H0v31.874zm29.583-2.293h-4.824v-6.283h-2.295v6.283H2.294V2.382h27.289V29.67z"></path>
                  <path d="M12.854 5.677h-2.53L5.64 17.737h2.422l1.265-3.382h4.541l1.247 3.382h2.424l-4.685-12.06zm-2.82 6.636l1.555-4.197 1.537 4.197h-3.093z"></path>
                </g>
              </svg>
            </Link>
            <FooterAllRights hideOnMobile />
          </div>
          <ul className={styles.footerSocialLinks}>
            <li>
              <WeChatIcon width="20px" height="20px" />
            </li>
            <li>
              <a
                href="https://twitter.com/artsy"
                target="_blank"
                rel="noreferrer"
              >
                <TwitterIcon width="20px" height="20px" />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/artsy"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon width="20px" height="20px" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/artsy/"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon width="20px" height="20px" />
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
