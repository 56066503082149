import React, { useState } from "react"

import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import NavBar from "../NavBar/NavBar"

import logo from "../../images/logo.png"
import hamburgerIcon from "../../images/hamburger.png"
import closeIcon from "../../images/closeLight.png"

import * as styles from "./Header.module.scss"
import Menu from "../Menu/Menu"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSale {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
    }
  `)

  const [showMenu, setShowMenu] = useState(false)

  return (
    <header className={styles.header}>
      <div className={styles.headerInner}>
        <div className={styles.headerTopNav}>
          <a href="https://www.artsy.net/">
            <svg
              height="40"
              width="40"
              name="Artsy"
              viewBox="0 0 32 32"
              fill="black100"
              className="Icon-sc-1eovgys-0 kAqktO"
            >
              <title>Artsy</title>
              <g fillRule="evenodd">
                <path d="M0 31.963h31.876V.09H0v31.874zm29.583-2.293h-4.824v-6.283h-2.295v6.283H2.294V2.382h27.289V29.67z"></path>
                <path d="M12.854 5.677h-2.53L5.64 17.737h2.422l1.265-3.382h4.541l1.247 3.382h2.424l-4.685-12.06zm-2.82 6.636l1.555-4.197 1.537 4.197h-3.093z"></path>
              </g>
            </svg>
          </a>

          <button
            onClick={() => setShowMenu(!showMenu)}
            className={styles.headerMenuToggle}
          >
            <svg
              style={{ transform: "scale(1.5)" }}
              viewBox="0 0 18 18"
              fill="black100"
              height="18px"
              width="18px"
              className="Icon-sc-1eovgys-0 kAqktO"
            >
              <title>Menu</title>
              <g fillRule="evenodd">
                <path d="M3 3h12.026v1H3zM3 8.512h12.026V9.5H3zM3 13.996h12.026V15H3z"></path>
              </g>
            </svg>
          </button>

          <ul className={styles.headerTopNavLinks}>
            <li>
              <a href="https://www.artsy.net/collect">Buy</a>
            </li>
            <li>
              <a href="https://www.artsy.net/consign">Sell</a>
            </li>
            <li>
              <a href="https://www.artsy.net/price-database">Price Database</a>
            </li>
            <li>
              <a href="https://www.artsy.net/articles">Editorial</a>
            </li>
          </ul>
        </div>
        <NavBar />
      </div>

      {showMenu && <Menu closeMenu={() => setShowMenu(false)} />}
    </header>
  )
}

export default Header
