exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-artworks-index-tsx": () => import("./../../../src/pages/all-artworks/index.tsx" /* webpackChunkName: "component---src-pages-all-artworks-index-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-terms-of-use-index-tsx": () => import("./../../../src/pages/terms-of-use/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-index-tsx" */),
  "component---src-templates-auction-index-tsx": () => import("./../../../src/templates/auction/index.tsx" /* webpackChunkName: "component---src-templates-auction-index-tsx" */),
  "component---src-templates-sale-index-tsx": () => import("./../../../src/templates/sale/index.tsx" /* webpackChunkName: "component---src-templates-sale-index-tsx" */)
}

