import React from "react"
import { Link } from "gatsby"

import * as styles from "./NavBar.module.scss"

const NavBar = () => {
  return (
    <div className={styles.navbar}>
      <ul className={styles.navbarList}>
        <li>
          <a href="https://www.artsy.net/artists">Artists</a>
        </li>
        <li>
          <a href="https://www.artsy.net/collect">Artworks</a>
        </li>
        <li>
          <a href="https://www.artsy.net/auctions">Auctions</a>
        </li>
        <li>
          <a href="https://www.artsy.net/viewing-rooms">Viewing Rooms</a>
        </li>
        <li>
          <a href="https://www.artsy.net/galleries">Galleries</a>
        </li>
        <li>
          <a href="https://www.artsy.net/art-fairs">Fairs</a>
        </li>
        <li>
          <a href="https://www.artsy.net/shows">Shows</a>
        </li>
        <li>
          <a href="https://www.artsy.net/institutions">Museums</a>
        </li>
        <li>
          <Link to="/" className={styles.navBarNFT}>
            NFTs
          </Link>
        </li>
      </ul>
      <ul className={styles.navbarList}>
        <>
          <li>
            <a href="">Download App</a>
          </li>
        </>
      </ul>
    </div>
  )
}

export default NavBar
