import React from "react"
import { RecoilRoot } from "recoil"
import { UseWalletProvider } from "use-wallet"

import Auth from "@aws-amplify/auth"

import { AmplifyProvider } from "@aws-amplify/ui-react"

// import "@aws-amplify/ui-react/styles.css"
import Layout from "./src/components/Layout/Layout"

import { Theme, injectGlobalStyles } from "@artsy/palette"

const { GlobalStyles } = injectGlobalStyles(`
  // overrides and additions
`)

export const wrapRootElement = ({ element }) => {
  const chainId = parseInt(process.env.GATSBY_ETHERNET_CHAIN_ID)

  return (
    <UseWalletProvider
      autoConnect
      connectors={{
        walletconnect: {
          chainId: chainId,
          rpc: { [chainId]: process.env.GATSBY_REACT_APP_ETHEREUM_RPC },
        },
      }}
    >
      <Theme>
        <GlobalStyles />
        <RecoilRoot>{element}</RecoilRoot>
      </Theme>
    </UseWalletProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}
