import React from "react"
import { Auth } from "aws-amplify"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useRecoilState } from "recoil"
import { isAuthenticatedState, userState, AuthState } from "../../state/user"
import { slugify } from "../../utils"

import * as styles from "./Menu.module.scss"

const Menu = ({ closeMenu }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSale {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
    }
  `)
  const [authState, setAuthState] = useRecoilState(isAuthenticatedState)
  const [user, setUser] = useRecoilState(userState)

  const signOut = async () => {
    try {
      await Auth.signOut()
      setAuthState(AuthState.unauthenticated)
    } catch {
      return false
    }
  }

  return (
    <menu className={styles.menu}>
      <ul className={styles.menuList}>
        <li>
          <a href="https://www.artsy.net/artists">Artists</a>
        </li>
        <li>
          <a href="https://www.artsy.net/collect">Artworks</a>
        </li>
        <li>
          <a href="https://www.artsy.net/auctions">Auctions</a>
        </li>
        <li>
          <a href="https://www.artsy.net/viewing-rooms">Viewing Rooms</a>
        </li>
        <li>
          <a href="https://www.artsy.net/galleries">Galleries</a>
        </li>
        <li>
          <a href="https://www.artsy.net/art-fairs">Fairs</a>
        </li>
        <li>
          <a href="https://www.artsy.net/shows">Shows</a>
        </li>
        <li>
          <a href="https://www.artsy.net/institutions">Museums</a>
        </li>
        <li>
          <Link to="/">NFTs</Link>
        </li>
      </ul>
      <ul className={styles.menuSecondList}>
        <li>
          <a href="https://www.artsy.net/collect">Buy</a>
        </li>
        <li>
          <a href="https://www.artsy.net/consign">Sell</a>
        </li>
        <li>
          <a href="https://www.artsy.net/price-database">Price Database</a>
        </li>
        <li>
          <a href="https://www.artsy.net/articles">Editorial</a>
        </li>
      </ul>
    </menu>
  )
}

export default Menu
