import {find} from "lodash"

export const enricheLotWithAuction = (lots: any, auctions: any) => {
  return lots?.map((lot: any) => {
    return {
      ...(lot.node ? lot.node : lot),
      auction: find(auctions, a => {
        return a.id === (lot.auctionId || lot.node.auctionId)
      }),
    }
  })
}

export const getAuctionsIds = (lots: any) => {
  return lots?.map((lot: any) => {
    return lot?.auctionId || lot?.node?.auctionId
  })
}

export interface AssociateWalletWithUserRequestData {
  wallet: string,
  name: string,
  email: string,
  partner: string,
  partnerID: string,
  eventType: string,
}

export const associateWalletWithUser = async (requestData: AssociateWalletWithUserRequestData) => {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(requestData)
  };
  await fetch('/api/wallets/events', requestOptions)
}